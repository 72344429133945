import React from 'react';

export default function TopBar(p) {
    return (
        <>
            <img id="mobilelogo" src='img/logo.png' className='Logo' alt='IOT Fortress' />
            <header className="TopBar">
                <div className='Mid'>
                    <div className='Nav'>
                        <img src='img/logo.png' className='Logo' alt='IOT Fortress' />
                        <a href='#features' className='Top Link'>Features</a>
                        <a href='#news' className='Top Link'>News</a>
                        <a href='#contact' className='Top Link'>Contact</a>
                    </div>
                    <div className='Actions'>
                        <a href='#contact' className='Button Secondary'>Request Demo</a>
                        {/* <a href='#' className='Button Primary'>TRY FREE DEMO</a> */}
                    </div>
                </div>
            </header>
        </>
    )
}