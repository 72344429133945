import React from 'react';


export default function Section2({children}) {
    return (
        <div className='Section2Wrapper'>
            <div className='Section2'>
                {children}
            </div>
        </div>
    )
}