import React from "react";

const Success = () => {
    return (
        <div className='success'>
            <p style={{fontWeight: 'bold'}}>Your message has been sent.</p>
            <p>Thank you for your interest in IoT Fortress!<br/>We'll contact you shortly.</p>
        </div>
    )
}


export default class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            status: ""
        };
    }

    render() {
        const { status } = this.state;

        return (
            <form
                onSubmit={this.submitForm}
                action="https://formspree.io/f/mleoaynv"
                method="POST"
            >
                {status === "SUCCESS" ? 
                    <Success />  
                    : 
                    <div>
                        <label>Email:</label>
                        <input type="email" name="email" />
                        <label>Message:</label>
                        <textarea name="message" rows={10}></textarea>
                        <div><button className='Button Primary'>Submit</button></div>
                        {status === "ERROR" && <p>Ooops! There was an error.</p>}
                    </div>
                }
            </form>
        );
    }

    submitForm(ev) {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                this.setState({ status: "SUCCESS" });
            } else {
                this.setState({ status: "ERROR" });
            }
        };
        xhr.send(data);
    }
}