import React from 'react';
import ContactForm from './ContactFormFormsSpree';
import Section2 from './Section2';

export default function Contact(p) {
    return (
        <Section2>
            <a name='contact'><h1>Contact us!</h1></a>
            <p className=''>If you are interested in IoT Fortress and want to try it yourself, drop us a line. We can schedule an online meeting and provide you access to demo accounts.</p>
            
            <div className='form'>
                <ContactForm />
            </div>
        </Section2>
    )
}