import React from 'react';
import Contact from './components/Contact';
import Feature from './components/Feature';
import FeaturesHeader from './components/FeaturesHeader';
import Filesystem from './components/Filesystem';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Highlights from './components/Highlights';
import TopBar from './components/TopBar';

function App() {
  return (
    <div className="App">
      <TopBar />

      <div className='Content'>

        <Hero />
        <Highlights />

        <FeaturesHeader />

        <Feature 
          title='Software composition' 
          content='IOT FORTRESS will scan the contents of the extracted firmware image to identify known software components. Identification is performed on binary files, no source code needed. ' 
          image='img/13-BOM.png'
          //link='#'
        />
        <Feature 
          title='Vulnerability assessment' 
          content='Get the list of known CVE vulnerabilities affecting the detected software component versions, classified by the risk impact. All identified components are checked against the NIST NVD database.' 
          image='img/05-cvs.png'
          inverse={true}
        />
        <Feature 
          title='Detailed reporting' 
          content='Use the web user interface to browse through the analysis results or get a full report in PDF format ready to include in your audits.' 
          image='img/00.png'
          //link='#'
        />

        <Filesystem />

        <Feature 
          title='Known exploits' 
          anchor='expl'
          content={
            <div>
              <p>Quickly get a list of well-known exploits for the Linux kernels detected in the firmware. This report provides links to public code for exploits of vulnerabilities affecting the specific versions the kernels detected.</p>
            </div>
          }
          image='img/16-expl.png'
          inverse={false}
        />


        <Feature 
          title='Firmware comparison' 
          content='Compare side by side different versions of the firmware and validate if issues have been addressed or potentially new ones have been introduced.' 
          image='img/14-diff.png'
          inverse={true}
        />

        <Feature 
          title='Cloud or on-premises' 
          content="Choose between our secure online platform in the cloud and start analyzing your device firmware images right away, or have IOT FORTRESS hosted in your own servers, so firmware images don't need to leave your organization." 
          image='img/cloud-vs-on-premises.png'
        />

        <Feature 
          title='Cryptographic analysis' 
          content='Certificates, private keys and password hashes are identified and included in the report. ' 
          image='img/12-certs.png'
          inverse={true}
        />

        <Feature 
          title='Binary hardening' 
          content='Get an extensive report of the hardening options used to build all the executables in the firmware image, with summary charts of the hardening coverage.' 
          image='img/01-2.png'
        />

        <Feature 
          title='Unwanted components' 
          content="Sometimes developers forget debugging tools embedded in production firmware, opening attacking vectors. IOT FORTRESS detects source code and version control files, as well as binaries used in development that don't belong in production firmware. "
          image='img/15-unwanted.png'
          inverse={true}
        />

        <Feature 
          title='Function usage' 
          content='IOT FORTRESS also checks the firmware image for executables using standard library functions considered insecure. It also enumerates programs that use networking functions or execute other programs.' 
          image='img/10-functions.png'
        />

        <Contact />

      </div>

      <Footer />
    </div>
  );
}

export default App;
