import React from 'react'

export default function FeaturesHeader() {
    return (
        <div className='Section FeaturesHeader'>
            <a name='features'><h1>Analyze and discover</h1></a>
            <p className=''>IOT FORTRESS is a firmware security audit tool. It analyzes the contents of your <strong>Linux-based</strong> firmware images and generates a complete security audit report. </p>
            <a href='#contact' className='Button Primary'>Get started</a>
        </div>
    )
}