import React from 'react';

export default function Hero(p) {
    return (
        <div className='Section Hero'>
            <h1>Complete Firmware Binary Analysis</h1>
            <p className='Subtitle'>Scan your devices' firmware binaries for known vulnerabilities and get a detailed report of potential risks.</p>
            <a href='#contact' className='Button Primary'>Get Started</a>
        </div>
    )
}