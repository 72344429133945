import React from 'react';
import Section2 from './Section2';


export default function Filesystem(p) {
    return (
        <Section2>
            <h1>File system browser</h1>
            <p className=''>IOT FORTRESS will unpack your linux-based firmware image and let you navigate through the extracted filesystem. You can download any file or view it using the included text and hex viewers. An interactive entropy chart can be used to navigate the binary files in the hex view. </p>
            <img alt='IoT Fortress filesystem animation showing how to navigate the file using the entropy chart.' src='img/06-entropy.gif' />
            <a className='Button Primary' href='#contact'>Request a demo</a>
        </Section2>
    )
}