import React from 'react';


export default function Feature({title, content, link, linkText, image, imageAlt, inverse, anchor}) {
    return (
        <div className={'Section Feature Mid' + (inverse ? ' Right' : '')}>
            <div className='Text'>
                <a name={anchor} /><h1>{title}</h1>
                <p className=''>{content}</p>
                {link && <a href={link}>{linkText || 'Read more'}</a>}
            </div>
            <div className='Image'>
                <img className='FeatureImg' src={image} alt={imageAlt} />
            </div>
        </div>
    )
}