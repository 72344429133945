import React from 'react'



function Item(p) {
    return (
        <div className='Item'>
            <h3>{p.section}</h3>
            <h1>{p.title}</h1>
            <p className='Content'>{p.content}</p>
            {p.link &&  <a href={p.link} className='Link'>Read more</a>}
        </div>
    )
}


export default function Highlights(p) {
    return (
        <div id="news" className='Section Mid Highlights'>
            <a name='news' />
            <Item 
                section='CLIENTS' 
                title='Orange is using IOT FORTRESS to audit router firmware  binaries'
                content='Orange Spain router and IoT cyberlab has been using IoT Fortress some time now. Their main concern and requirement is that their firmware binaries should not leave their premises.'
                fullContent='Orange Spain router and IoT cyberlab started using IOT FORTRESS some time now. Their main concern and requirement is that their firmware binaries should not leave their premises, therefore an encrypted VM instance has been delivered and configured on ORANGE cyberlab premises to allow them to use the full fledged IOT FORTRESS platform. 
                Orange cyberlab testimony “Dyquo labs has been a great support for us, we requested a couple of features and they did not hesitate to promptly respond and implement the changes. Using IOT FORTRESS to regularly audit our firmware binaries we have been able to reduce execution and analysis time by approximately 30-40%, we are looking forward and eager to use the Dynamic Analysis module when it will be launched”'
            />
            <Item 
                section='NEWS' 
                title='New "Known exploits" analysis'
                content='Latest release improves reporting of public and well known exploits affecting the firmware. '
                link='#expl'
            />
            <Item 
                section='EVENTS' 
                title="Don't miss our next online session showing the latest additions"
                content='We regularly schedule online sessions to present the latest features and improvements. Contact us using the form below if you are interested in attending.'
                link='#contact'
            />

        </div>
    )
}